<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>Créer un contact</h1>
            <b-button
                size="sm"
                variant="outline-secondary"
                @click="goListContacts"
            >
                <b-icon-list-ul /> Liste des contacts
            </b-button>
        </div>

        <div class="k-page-body">
            <FormContact />
        </div>
    </div>
</template>
<script>
import FormContact from "@/components/model/contact/FormContact";
export default {
    components: {
        FormContact,
    },

    methods: {
        goListContacts() {
            this.$router.push({
                name: "AdminListContacts",
            });
        },
    },
};
</script>
