<template>
    <div>
        <div class="text-left">
            <b-form-group>
                <label> <b>Nom</b> <span class="text-danger">*</span> </label>
                <b-form-input v-model="name" :state="isNameValid" trim />
                <b-form-invalid-feedback :state="isNameValid">
                    Veuillez saisir le nom du contact
                </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group>
                <label> Rôle, titre, poste </label>
                <b-form-input v-model="role" trim />
            </b-form-group>

            <b-form-group>
                <label>Email</label>
                <b-form-input v-model="mail" :state="isMailValid" trim />
                <b-form-invalid-feedback :state="isMailValid">
                    Veuillez saisir une adresse e-mail valide
                </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group>
                <label>Adresse</label>
                <b-form-input v-model="address" trim />
            </b-form-group>

            <b-form-group>
                <label>Numéro de téléphone</label>
                <b-form-input v-model="phone" type="tel" trim />
            </b-form-group>
        </div>
        <div class="text-center">
            <b-button
                v-if="!isEditMode"
                variant="kalkin-2"
                @click="createContact()"
            >
                <b-icon-plus-circle /> Créer le contact
            </b-button>
            <b-button v-else variant="kalkin-2" @click="updateContact()">
                <b-icon-check-circle-fill /> Enregistrer les modifications
            </b-button>
        </div>
    </div>
</template>
<script>
import validator from "@/tools/validator";

export default {
    props: {
        contact: { type: Object, required: false },
    },

    data: function() {
        if (this.contact) {
            return {
                isEditMode: true,
                name: this.contact.name,
                role: this.contact.role,
                mail: this.contact.mail,
                address: this.contact.address,
                phone: this.contact.phone,
            };
        } else {
            return {
                isEditMode: false,
                name: null,
                role: null,
                mail: null,
                address: null,
                phone: null,
            };
        }
    },

    computed: {
        isNameValid() {
            if (this.name === null) {
                return null;
            } else {
                return this.name !== "";
            }
        },

        isMailValid() {
            if (this.mail === null || this.mail === "") {
                return null;
            } else {
                return validator.validateEmail(this.mail);
            }
        },

        isValid() {
            return this.isMailValid !== false && this.isNameValid;
        },
    },

    methods: {
        createContact: async function() {
            if (this.isValid) {
                await this.$store.dispatch("contact/createContact", {
                    name: this.name,
                    role: this.role,
                    mail: this.mail,
                    address: this.address,
                    phone: this.phone,
                });
                this.$router.push({ name: "AdminListContacts" });
            } else {
                this.name = this.name || "";
            }
        },

        updateContact: async function() {
            if (this.isValid) {
                await this.$store.dispatch("contact/updateContact", {
                    contactId: this.contact.id,
                    name: this.name,
                    role: this.role,
                    mail: this.mail,
                    address: this.address,
                    phone: this.phone,
                });
                this.$emit("saved");
            }
        },
    },
};
</script>
